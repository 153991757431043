import React from 'react'
import Content from '../Content'
import { kebabCase } from 'lodash'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { SubscribeForm } from '../common/subscribeForm'
import { Author } from '../common/author'
import { Link as ScrollLink, Element } from 'react-scroll'

/**
 * Attempt to copy the style of Medium here
 */
const BlogArticleWrapper = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, "San Francisco",
    "Helvetica Neue", Helvetica, Ubuntu, Roboto, Noto, "Segoe UI", Arial,
    sans-serif;

  max-width: 900px;

  p {
    margin-top: 2rem;
    font-size: 1.4rem;
    font-weight: 400;
  }

  a {
    text-decoration: underline;
  }

  ul li {
    font-size: 1.3rem;
    list-style-type: disc;
  }

  ol li {
    font-size: 1.3rem;
  }

  ol {
    padding: 1rem;
  }

  h1 {
    font-size: 3em !important;
  }

  h2 {
    font-size: 2.2em !important;
  }

  .imgBorders img {
    margin: 1rem;
    border-style: solid;
    border-size: 2px;
    border-color: #555;
  }

  .coverImage {
    display: block;
    margin: auto;
    text-align: center;
  }
`

const CreditWrapper = styled.div`
  color: #888;
`

const SubScribeMessage = () => {
  return (
    <div className='message is-primary'>
      <div className='message-header'>
        <p style={{ margin: 0, color: 'white', fontWeight: 'bold' }}>
          Want updates?
        </p>
        <button className='delete' aria-label='delete' />
      </div>
      <div className='message-body'>
        <p>
          Want new posts about tech topics emailed to you? Sign up to the list
          below 👇
        </p>
        <p>
          Also, if you are interested in learning technical topics through a
          video course specifically created for recruiters, don't forget to{' '}
          <Link to='/courses'>check out the courses I offer</Link>.
        </p>
        <p>
          The main course{' '}
          <Link to='/courses'>
            "How to Speak Software Engineering Jargon for Recruiters"
          </Link>{' '}
          is specifically designed to help tech recruiters get up to speed fast
          on technical topics.
        </p>
      </div>
    </div>
  )
}

const ArticleTemplate = ({
  content,
  contentComponent,
  cover,
  meta_title,
  meta_desc,
  date,
  tags,
  title,
  author,
  coverCredit,
  classes,
}) => {
  // This is: import { HTMLContent } from "../components/Content";
  const PostContent = contentComponent || Content
  return (
    <BlogArticleWrapper>
      <h1 className='title is-size-2 has-text-weight-bold is-bold-light'>
        {title}
      </h1>
      <img src={cover} alt={title} className='coverImage' />
      <CreditWrapper style={{ marginBottom: '1rem' }}>
        <span>Post Author: </span>
        <ScrollLink to='author-bio' offset={-50}>
          <span className='has-text-weight-semibold'> {author.id}</span>
        </ScrollLink>{' '}
        {coverCredit ? (
          <>
            | <span className='is-italic'>{coverCredit}</span>
          </>
        ) : null}
        <br />
        <span>Date published: {date}</span>
      </CreditWrapper>
      <div className={classes ? classes.join(' ') : ''}>
        <PostContent content={content} />
        <hr />
        <SubScribeMessage />
      </div>
      <SubscribeForm />
      <Element name='author-bio'>
        <hr />
        <Author author={author} />
      </Element>
      <div style={{ marginTop: `4rem` }}>
        <h4>Tags</h4>
        <ul className='taglist'>
          {tags && tags.length
            ? tags.map(tag => (
              <li key={tag + `tag`}>
                <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
              </li>
            ))
            : null}
        </ul>
      </div>
      <hr />
    </BlogArticleWrapper>
  )
}

export default ArticleTemplate
