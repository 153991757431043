import React from 'react'
import styled from 'styled-components'

const WrapperBackground = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`

const Inner = styled.div`
  margin: 1rem;
`

const SocialWrap = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
`

const H4Small = styled.h4`
  margin-top: 0;
`

export const Author = ({ author }) => {
  const linkedin = author.social.find(s => s.name === 'linkedin')
  const twitter = author.social.find(s => s.name === 'twitter')
  return (
    <WrapperBackground>
      <Inner style={{ width: '20%', minWidth: '120px', textAlign: 'center' }}>
        <img src={author.avatar} style={{ maxWidth: '120px' }} />
        <SocialWrap>
          {linkedin ? (
            <a
              rel='noopener norefferer'
              target='_blank'
              href={linkedin.url}
            >
              <img style={{ height: '2rem' }} src='/img/ln-icon.svg' />
            </a>
          ) : null}
          {twitter ? (
            <a
              rel='noopener norefferer'
              target='_blank'
              href={twitter.url}
            >
              <img
                style={{ height: '2rem' }}
                src='/img/twitter-rounded-square.png'
              />
            </a>
          ) : null}
        </SocialWrap>
      </Inner>

      <Inner style={{ width: '60%' }}>
        <H4Small className='has-text-info'>Written By {author.id}</H4Small>
        <p className='is-size-6'>{author.bio}</p>
      </Inner>
    </WrapperBackground>
  )
}
